import React from 'react';
import moment from 'moment';

export class Form extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formId: props.id || 'form-' + StringHelper.random(16),
            csrfToken: jQuery('meta[name="csrf-token"]').attr('content'),
            errors: props.errors || {},
            errorsExpanded: false
        };

        this.formRef = React.createRef();
    }

    render() {
        return <form ref={this.formRef} id={this.state.formId} className={this.props.className} action={this.props.action} method={this.props.method || 'post'} onSubmit={this.submit}>
            <input type="hidden" name="authenticity_token" defaultValue={this.state.csrfToken} />
            <div className="row">
                <div className={this.props.errorClasses || 'col'}>
                    {this.renderErrors(this.state.errors)}
                </div>
            </div>
            {this.props.children}
        </form>;
    }

    confirm() {
        if (!this.props.confirm) {
            return true;
        } else if (typeof this.props.confirm == 'function') {
            return this.props.confirm();
        } else {
            return confirm(this.props.confirm);
        }
    }

    submit = (event) => {
        if (!this.confirm()) {
            event.preventDefault();
            return false;
        }

        const errors = this.validate();
        let form = jQuery(event.currentTarget);
        let submitBtn = form.find('[type="submit"]');

        if (Object.values(errors).length == 0) {
            // return true in props.onSubmit if you don't handle submit in it.
            if (typeof this.props.onSubmit != 'function' || this.props.onSubmit()) {
                if(this.props?.className !== "login-form"){
                    submitBtn.prop('disabled', true).attr('data-orig-label', submitBtn.html()).html(I18n.t('loading'));
                }

                let data = null;

                if (typeof this.props.data == 'function') {
                    data = this.props.data();
                    data.authenticity_token = this.state.csrfToken;
                } else {
                    data = form.serialize();
                }

                jQuery.post(form.attr('action'), data, (result) => {

                    this.setState({ errors: {} });

                    if (this.props.revertSubmitOnSuccess) {
                        submitBtn.prop('disabled', false).html(submitBtn.attr('data-orig-label'));
                    }
                    if (this.props.onSuccess) {
                        this.props.onSuccess(result, this.formRef.current);
                    }
                }).fail((response) => {
                    let errors = {};

                    if (response.responseJSON) {
                        errors = response.responseJSON.errors;
                    } else {
                        errors = {
                            server: [{ message: I18n.t('server_error_message') }]
                        }
                    }

                    this.setState({ errors: errors }, () => {
                        submitBtn.prop('disabled', false).html(submitBtn.attr('data-orig-label'));

                        if (this.props.onError) {
                            this.props.onError(response, this.formRef.current);
                        }
                    });
                });
            }
        } else {
            this.setState({ errors: errors });
        }
        event.preventDefault();
    }

    renderErrors(object) {
        if (Object.keys(object || {}).length > 0) {
            let errors = [];

            for (let attr in object) {
                object[attr].forEach((e, i) => {
                    errors.push(<li key={attr + i}>{e.message}</li>);
                });
            }
            return <div className="alert alert-danger form-errors" onClick={this.toggleErrors}>
                <p className="mb-0">
                    <a href="#" onClick={e => e.preventDefault()} className={'float-right ' + (this.state.errorsExpanded ? 'text-dark' : 'text-danger')}>
                        <i className="fa fa-chevron-down"></i>
                    </a>
                    {I18n.t('errors_title')}
                </p>
                {this.state.errorsExpanded ? <ul className="list-unstyled mt-4 mb-0">
                    {errors}
                </ul> : null}
            </div>;
        }
        return null;
    }

    validate = () => {
        let errors = {};

        (this.props.validations || []).forEach((field) => {
            let input = field.id ? jQuery(`#${field.id}`) : jQuery(`[name="${field.name}"]`);
            let value = input.val();

            (field.validations || []).forEach((method) => {
                switch (method) {
                    case 'presence':
                        if (input.is('[type="radio"]')) {
                            if (input.filter(':checked').length == 0) {
                                // Add error
                                errors[field.name] = errors[field.name] || [];
                                errors[field.name].push({ message: I18n.t(`error_param_${method}`, { param: I18n.t(field.label) }) });
                            }
                        } else if (!value || value.trim() == '') {
                            // Add error
                            errors[field.name] = errors[field.name] || [];
                            errors[field.name].push({ message: I18n.t(`error_param_${method}`, { param: I18n.t(field.label) }) });
                        }
                        break;
                    case 'dateness':
                        if (value && value.trim != '') {
                            if (!moment(value, 'DD.MM.YYYY', true).isValid()) {
                                errors[field.name] = errors[field.name] || [];
                                errors[field.name].push({ message: I18n.t(`error_param_${method}`, { param: I18n.t(field.label) }) });
                            }
                        }
                        break;
                }
            });
        });

        return errors;
    }

    toggleErrors = (event) => {
        this.setState({ errorsExpanded: !this.state.errorsExpanded });
        event.preventDefault();
    }

    componentDidMount() {
        // Highlighting also errors that have been passed as props
        this.highlightErrors();
        this.initializeTooltips();
    }

    componentDidUpdate() {
        this.highlightErrors();
        this.initializeTooltips();
    }

    highlightErrors() {
        // Clear highlights
        jQuery(this.formRef.current).find('input, select, textarea, .react-select-bootstrap').removeClass('is-invalid');
        jQuery(this.formRef.current).find('.invalid-feedback').remove();

        // Highlight fields with errors
        if (Object.keys(this.state.errors || {}).length > 0) {
            for (let fieldName in this.state.errors) {
                let appended = {};
                jQuery(this.formRef.current).find('input, select, textarea').filter(`[name*="${fieldName}"]`).each((i, input) => {
                    input = jQuery(input);
                    let feedback = jQuery('<div class="invalid-feedback"></div>');

                    if (input.is('[type="radio"], [type="checkbox"]')) {
                        input.closest(':not(label, input)').append(feedback);
                    } else if (input.is('[type="hidden"]')) {
                        // Prevent error message to going inside of toggle of Select component
                        if (input.closest('label').length > 0) {
                            input.closest('label').append(feedback);
                        } else if (input.closest('.react-select-bootstrap').length > 0) {
                            input.closest('.react-select-bootstrap').after(feedback);
                        } else {
                            input.closest(':not(input)').append(feedback);
                        }
                        // Input might be a custom react select
                        input.closest('.react-select-bootstrap').addClass('is-invalid');
                    } else {
                        appended = {}; // Reset for inputs that should exist only once per field name
                        input.addClass('is-invalid').parent().append(feedback);
                    }

                    this.state.errors[fieldName].forEach((e) => {
                        if (e.highlight_selector && !input.is(e.highlight_selector)) {
                            return;
                        }
                        if (!appended[e.message]) {
                            if (e.feedback_selector) {
                                const feedbackContainer = jQuery(e.feedback_selector);
                                feedback.remove();
                                feedback = jQuery(`<div class="invalid-feedback">${e.message}</div>`);

                                if (feedbackContainer.text().indexOf(e.message) == -1) {
                                    feedbackContainer.append(feedback);
                                }
                            } else {
                                feedback.append(`<div>${e.message}</div>`);
                            }
                            appended[e.message] = true;
                        }
                    });

                    // Reset highlight if no messages were appended
                    if (Object.keys(appended).length > 0) {
                        feedback.show();
                    } else {
                        feedback.remove();
                        input.removeClass('is-invalid');
                        input.closest('.react-select-bootstrap').removeClass('is-invalid');
                    }
                });
            }
        }
    }

    initializeTooltips() {
        jQuery(this.formRef.current).find('[data-toggle="tooltip"]').tooltip();
    }
}

export class FormSubmit extends React.Component {
    render() {
        return <button type="submit" className={this.props.className || 'btn btn-primary px-4'} disabled={this.props.disabled}>
            {this.props.children || I18n.t('save')}
        </button>;
    }
}