import React, { useState } from "react";
import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import TextField from "@oriola-origo/core/lib/TextField";
import IconButton from "@oriola-origo/core/lib/IconButton";
import InputAdornment from "@oriola-origo/core/lib/InputAdornment";

interface IShowPasswordProps {
  name: string;
  placeHolder: string;
  isErrorKey: boolean;
  onValueChange: (value: string) => void;
  helperText?: string;
  onFocusChange: () => void;
  onBlurChange: () => void;
  isRequired: boolean;
}

export function handleClickShowPassword(showPassword, setShowPassword) {
  setShowPassword(!showPassword);
}

const ShowPassword = (props: IShowPasswordProps) => {
  const {
    name,
    placeHolder,
    isErrorKey,
    onValueChange,
    helperText,
    onFocusChange,
    onBlurChange,
    isRequired,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      id="password"
      sx={{ paddingBottom: "5px !important" }}
      required={isRequired}
      fullWidth
      name={name}
      label={placeHolder}
      helperText={isErrorKey && I18n.t(helperText)}
      error={isErrorKey}
      onChange={(e) => onValueChange(e.target.value)}
      onFocus={onFocusChange}
      onBlur={onBlurChange}
      InputProps={{
        type: showPassword ? "text" : "password",
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() =>
                handleClickShowPassword(showPassword, setShowPassword)
              }
              edge="end"
            >
              {showPassword ? (
                <FontIcon>visibility_off</FontIcon>
              ) : (
                <FontIcon>visibility</FontIcon>
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ShowPassword;
