import React, { useState } from "react";
import { Form } from "../common/form";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import Typography from "@oriola-origo/core/lib/Typography";
import TextField from "@oriola-origo/core/lib/TextField";
import Button from "@oriola-origo/core/lib/Button";
import { validateEmailAddress } from "./form_validation";
import { styled } from "@oriola-origo/core/lib/styles";
import Box from "@oriola-origo/core/lib/Box";

export interface IForgotPasswordProps {
  forgot_password_url: string;
  logout_url: string;
}
const ForgotPassword = (props: IForgotPasswordProps) => {
  const { forgot_password_url, logout_url } = props;
  const [isEmailValid, setIsEmailValid] = useState(true);
  const RequestCodeButtonContainer = styled(Box)(({ theme }) => ({
    margin: theme.spacing(4, 0, 3, 0),
  }));
  return (
    <Form action={forgot_password_url}>
      <OriolaThemeProvider>
        <Typography variant="h5" fontWeight={600}>
          {I18n.t("forgot_password_link")}
        </Typography>
        <br />
        <TextField
          fullWidth={true}
          required={true}
          label={I18n.t("email_address")}
          name="email_address"
          onChange={(e) =>
            setIsEmailValid(!validateEmailAddress("", e.target.value).error)
          }
          error={!isEmailValid}
          helperText={!isEmailValid && I18n.t("error_invalid_email_address")}
        />
        <Typography variant="body1" sx={{ marginTop: "10px" }} color="GrayText">
          {I18n.t("forgot_password_message")}
        </Typography>
        <RequestCodeButtonContainer>
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            disabled={!isEmailValid}
            fullWidth={true}
            sx={{ padding: (theme) => theme.spacing(1, 0) }}
          >
            {I18n.t("request_code")}
          </Button>
        </RequestCodeButtonContainer>
        <hr style={{ marginBottom: "10px" }} />
        <div className="d-flex justify-content-center mb-n2">
          <a style={{ fontSize: "14px" }} href={logout_url}>
            {I18n.t("cancel")}
          </a>
        </div>
      </OriolaThemeProvider>
    </Form>
  );
};

export default ForgotPassword;
