import React, { useState } from "react";
import { Form } from "../common/form";
import TextField from "@oriola-origo/core/lib/TextField";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import Button from "@oriola-origo/core/lib/Button";
import Typography from "@oriola-origo/core/lib/Typography";
import Box from "@oriola-origo/core/lib/Box";

interface VerifyEmailProps {
  email_address: string;
  verification_code: string;
}

export const renderVerifyEmailTypography = () => {
  return (
    <Box className="col-12 col-md-12">
      <Typography variant="h5">
        {I18n.t("verify_email")}
      </Typography>
    </Box>
  );
};

export const renderEmailAddressTextField = (emailAddress, setEmailAddress) => {
  return (
    <Box className="col-12 col-md-12">
      <TextField
        id="email_address"
        disabled={!!emailAddress}
        fullWidth={true}
        name="email_address"
        defaultValue={emailAddress}
        autoComplete="new-password"
        required={true}
        label={I18n.t("email_address")}
        onChange={(e) => setEmailAddress(e.target.value)}
        autoFocus={!emailAddress}
      />
    </Box>
  );
};

export const renderVerificationCodeTextField = (
  verificationCode,
  verificationFailed,
  verifyText,
  emailAddress,
  setVerificationCode
) => {
  return (
    <Box className="col-12 col-md-12">
      <TextField
        id="verification_code"
        fullWidth={true}
        name="code"
        value={verificationCode}
        autoComplete="new-password"
        required={true}
        label={I18n.t("verification_code")}
        autoFocus={!!emailAddress}
        error={verificationFailed}
        helperText={verifyText}
        onChange={(e) => setVerificationCode(e.target.value)}
      />
    </Box>
  );
};

export const onSuccess = (result) => {
  window.location.assign(result.redirect_url);
};

export const onVerificationError = (
  error,
  setVerifyText,
  setVerificationFailed
) => {
  if (error.status == 400 || error.status == 500) {
    setVerifyText(I18n.t("error_invalid_verification_code"));
    setVerificationFailed(true);
  }
};

export const renderVerifyButton = () => {
  return (
    <Box className="col-12 col-md-12">
      <Button
        color="secondary"
        variant="contained"
        type="submit"
        disabled={false}
        fullWidth={true}
        sx={{ padding: (theme) => theme.spacing(1.2) }}
      >
        {I18n.t("verify")}
      </Button>
    </Box>
  );
};

export const renderResendCodeButton = () => {
  return (
    <Button
      className="resend-validation-code-btn"
      color="secondary"
      variant="contained"
      type="submit"
      disabled={false}
      fullWidth={true}
    >
      {I18n.t("resend_verification_code")}
    </Button>
  );
};

export const updateTextOnCodeSent = (setVerifyText, setVerificationCode) => {
  setVerifyText(I18n.t("verify_email_message"));
  setVerificationCode("");
};

export const renderSendVerificationCodeForm = (
  emailAddress,
  setVerifyText,
  setVerificationCode
) => {
  return (
    <Form
      className="resend-verification-code-form"
      action={Routes.send_verification_code_path()}
      revertSubmitOnSuccess={true}
      onSuccess={() => {
        updateTextOnCodeSent(setVerifyText, setVerificationCode);
      }}
    >
      <input type="hidden" name="email_address" defaultValue={emailAddress} />
      {renderResendCodeButton()}
    </Form>
  );
};

function UserVerifyEmail(props: Readonly<VerifyEmailProps>) {
  const { email_address, verification_code } = props;
  const [emailAddress, setEmailAddress] = useState(email_address);
  const [verificationCode, setVerificationCode] = useState(verification_code);
  const [verifyText, setVerifyText] = useState(I18n.t("verify_email_message"));
  const [verificationFailed, setVerificationFailed] = useState(false);

  return (
    <OriolaThemeProvider>
      <Box>
        <Form
          className="verify-email-form"
          action={Routes.confirm_verify_email_path({ locale: I18n.locale })}
          onSuccess={onSuccess}
          onError={(e) =>
            onVerificationError(e, setVerifyText, setVerificationFailed)
          }
        >
          <Box className="row" style={{ rowGap: "30px" }}>
            <input type={"hidden"} name={"email_address"} value={emailAddress} />
            {renderVerifyEmailTypography()}
            {renderEmailAddressTextField(emailAddress, setEmailAddress)}
            {renderVerificationCodeTextField(
              verificationCode,
              verificationFailed,
              verifyText,
              emailAddress,
              setVerificationCode
            )}
            {renderVerifyButton()}
          </Box>
        </Form>

        <Box className="row">
          <Box className="col-12 col-md-12 text-center">
            {renderSendVerificationCodeForm(
              emailAddress,
              setVerifyText,
              setVerificationCode
            )}
            <hr style={{ margin: 0, padding: 0 }} />
          </Box>
          <Box className="col-12 col-md-12 text-center verify-email-cancel">
            <a href={Routes.login_path()} className="btn btn-link mt-3">
              {I18n.t("cancel")}
            </a>
          </Box>
        </Box>
      </Box>
    </OriolaThemeProvider>
  );
}

export default UserVerifyEmail;
