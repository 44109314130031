import React, { useState } from "react";
import { Form } from "../common/form";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import Typography from "@oriola-origo/core/lib/Typography";
import TextField from "@oriola-origo/core/lib/TextField";
import Button from "@oriola-origo/core/lib/Button";
import { useTheme } from "@oriola-origo/core/lib/styles";

import ShowPassword from "../common/show_password";
import { onPasswordChange, onPasswordConfirmationChange } from "./form_utils";
import PasswordHints from "./components/password_hints";
import Box from "@oriola-origo/core/lib/Box";
import { useMediaQuery } from "@material-ui/core";

export interface IForgotPasswordSetProps {
  confirm_forgot_password_set_url: string;
  logout_url: string;
  email_verified: boolean;
  password_chars: string;
}
const ForgotPasswordSet = (props: IForgotPasswordSetProps) => {
  const {
    confirm_forgot_password_set_url,
    logout_url,
    email_verified,
    password_chars,
  } = props;
  const [formErrors, setFormErrors] = useState([]);
  const [typedUserPassword, setTypedUserPassword] = useState("");
  const [showPasswordHints, setShowPasswordHints] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeTouched, setVerificationCodeTouched] = useState(false);
  const theme = useTheme();
  const isSmOrSmaller = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Form action={confirm_forgot_password_set_url}>
      <OriolaThemeProvider>
        <Typography
          variant="h5"
          fontWeight={600}
          sx={{ marginBottom: (theme) => theme.spacing(3) }}
        >
          {I18n.t("verification_code")}
        </Typography>

        <TextField
          fullWidth={true}
          required={true}
          label={I18n.t("verification_code")}
          name="code"
          autoComplete="cc-name"
          autoFocus={true}
          sx={{ marginBottom: (theme) => theme.spacing(0.5) }}
          onClick={() => setVerificationCodeTouched(true)}
          onChange={(e) => setVerificationCode(e.target.value)}
          error={verificationCodeTouched && !verificationCode}
        />
        {email_verified || (
          <Typography variant="body1" color="GrayText">
            {I18n.t("forgot_password_ensure_correct_email_message")}
          </Typography>
        )}

        <Box
          display="grid"
          gridTemplateColumns={isSmOrSmaller ? "1fr" : "auto auto"}
          columnGap="15px"
          style={{ padding: 0, margin: "20px 0" }}
        >
          <Box>
            <ShowPassword
              name="password"
              placeHolder={I18n.t("password")}
              isErrorKey={!!formErrors["user_password"]}
              onValueChange={(value) => {
                onPasswordChange(value, setFormErrors, () => {});
                setTypedUserPassword(value);
              }}
              onFocusChange={() => setShowPasswordHints(true)}
              onBlurChange={() => setShowPasswordHints(false)}
              isRequired={true}
            />
            {showPasswordHints && (
            <PasswordHints
              password_chars={password_chars}
              typedUserPassword={typedUserPassword}
            />
          )}
          </Box>
          <Box>
            <ShowPassword
              name="password_confirmation"
              placeHolder={I18n.t("retype_password")}
              isErrorKey={!!formErrors["password_confirmation"]}
              helperText={"error_invalid_password_confirmation"}
              onValueChange={(value) =>
                onPasswordConfirmationChange(
                  value,
                  setFormErrors,
                  typedUserPassword
                )
              }
              onFocusChange={() => setShowPasswordHints(false)}
              onBlurChange={() => setShowPasswordHints(false)}
              isRequired={true}
            />
          </Box>          
        </Box>

        <Button
          color="secondary"
          variant="contained"
          type="submit"
          fullWidth={true}
          disabled={formErrors.length > 0 || !verificationCode}
          sx={{ padding: (theme) => theme.spacing(1, 0) }}
        >
          {I18n.t("log_in")}
        </Button>

        <hr />
        <div className="d-flex justify-content-center mb-n2">
          <a style={{ fontSize: "14px" }} href={logout_url}>
            {I18n.t("cancel")}
          </a>
        </div>
      </OriolaThemeProvider>
    </Form>
  );
};

export default ForgotPasswordSet;
