import {
  validateUserPassword,
  validatePasswordConfirmation,
} from "./form_validation";

export const onPasswordChange = (
  value: string,
  setFormErrors: any,
  setTypedUserPassword: any
) => {
  const { key, error } = validateUserPassword("user_password", value);

  setFormErrors((prevState) => ({
    ...prevState,
    [key]: error,
  }));

  setTypedUserPassword((prevState) => ({
    ...prevState,
    typed_user_password: value,
  }));
};

export const onPasswordConfirmationChange = (
  value: string,
  setFormErrors: any,
  user_password: string
) => {
  const { key, error } = validatePasswordConfirmation(
    "password_confirmation",
    user_password,
    value
  );
  setFormErrors((prevState) => ({
    ...prevState,
    [key]: error,
  }));
};
