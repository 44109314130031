/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import ReactDOM from "react-dom";
import * as Routes from "routes.js.erb";

window.Routes = Routes;

window.mountReactComponent = function (componentId, componentName, props) {
  ReactDOM.render(
    React.createElement(Webpack[componentName], props),
    document.getElementById(componentId)
  );
};

export { default as UserSignup } from "../components/user/signup";
export { default as UserLogin } from "../components/user/login";
export { default as UserVerify } from "../components/user/verify";
export { default as ForgotPassword } from "../components/user/forgot_password";
export { default as ForgotPasswordSet } from "../components/user/forgot_password_set";
