import FontIcon from "@oriola-origo/core/lib/Icons/FontIcon";
import Typography from "@oriola-origo/core/lib/Typography";
import { useTheme } from "@oriola-origo/core/lib/styles";
import React from "react";

export const passwordCheckValidity = (
  error_key: string,
  isValid: boolean,
  passwordSpecialChars: string,
  hasSpecialChars = false
) => {
  const theme = useTheme();
  return (
    <div className="password-hints">
      <FontIcon
        baseClassName={isValid ? "material-icons" : "material-icons-outlined"}
        fontSize="small"
        sx={{
          color: isValid
            ? theme.palette.success.main
            : theme.palette.error.main,
        }}
      >
        {isValid ? "check_circle" : "do_not_disturb_on"}
      </FontIcon>
      <Typography
        variant="body1"
        sx={{
          color: isValid
            ? theme.palette.success.main
            : theme.palette.error.main,
        }}
      >
        {I18n.t(error_key)}{" "}
        {hasSpecialChars && (
          <>
            <br />
            <span>{passwordSpecialChars}.</span>
          </>
        )}
      </Typography>
    </div>
  );
};

interface IPasswordHintsProps {
  typedUserPassword: string;
  password_chars: string;
}
const PasswordHints = (props: IPasswordHintsProps) => {
  const { typedUserPassword, password_chars } = props;
  return (
    <div className="password-fields" style={{ lineHeight: "2", marginBottom: "5px" }}>
      {passwordCheckValidity(
        "error_invalid_password_length",
        (typedUserPassword?.length || 0) > 9,
        typedUserPassword
      )}
      {passwordCheckValidity(
        "error_invalid_password_capital_letter",
        /[A-Z]/.test(typedUserPassword),
        typedUserPassword
      )}
      {passwordCheckValidity(
        "error_invalid_password_non_capital_letter",
        /[a-z]/.test(typedUserPassword),
        typedUserPassword
      )}
      {passwordCheckValidity(
        "error_invalid_password_number",
        /\d/.test(typedUserPassword),
        typedUserPassword
      )}
      {passwordCheckValidity(
        "error_invalid_password_special_chars",
        /[!@#$%^&*(),.?":{}|<>]/.test(typedUserPassword),
        password_chars,
        true
      )}
    </div>
  );
};

export default PasswordHints;
