import React, { useState } from "react";
import { Form } from "../common/form";
import TextField from "@oriola-origo/core/lib/TextField";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import Button from "@oriola-origo/core/lib/Button";
import Typography from "@oriola-origo/core/lib/Typography";
import ShowPassword from "../common/show_password";
import { validateEmailAddress } from "./form_validation";

export interface LoginProps {
  login_url: string;
}

export const onError = (error, setLoginText) => {
  if (error.status == 400 || error.status == 500) {
    setLoginText(I18n.t("log_in"));
  }
};

export const onSuccess = (result) => {
  window.location.assign(result.redirect_url);
};

export const renderLoginButton = (loginText, setLoginText) => {
  return (
    <Button
      id="login-btn"
      className="login-btn"
      color="secondary"
      variant="contained"
      type="submit"
      disabled={false}
      fullWidth={true}
      sx={{
        padding: (theme) => theme.spacing(1.2),
        marginTop: (theme) => theme.spacing(4),
      }}
      onClick={() => setLoginText(I18n.t("authenticating"))}
    >
      {loginText}
    </Button>
  );
};

function UserLogin(props: LoginProps) {
  const { login_url } = props;
  const [loginText, setLoginText] = useState(I18n.t("log_in"));
  const [isEmailValid, setIsEmailValid] = useState(true);

  return (
    <Form
      action={login_url}
      onSuccess={onSuccess}
      onError={(e) => onError(e, setLoginText)}
      className="login-form"
    >
      <OriolaThemeProvider>
        <Typography variant="h5" sx={{ lineHeight: "29.65px" }}>
          {I18n.t("log_in")}
        </Typography>
        <br />
        <div className="row login-forms">
          <div className="col-12 col-md-12">
            <TextField
              id="username"
              fullWidth={true}
              name="username"
              defaultValue=""
              autoComplete="email"
              required={false}
              label={I18n.t("email_address")}
              onChange={(e) =>
                setIsEmailValid(!validateEmailAddress("", e.target.value).error)
              }
              error={!isEmailValid}
              helperText={
                !isEmailValid && I18n.t("error_invalid_email_address")
              }
            />
          </div>

          <div className="col-12 col-md-12">
            <ShowPassword
              name="password"
              placeHolder={I18n.t("password")}
              isErrorKey={false}
              onValueChange={() => {}}
              helperText=""
              onFocusChange={() => {}}
              onBlurChange={() => {}}
              isRequired={false}
            />
          </div>
        </div>
        {renderLoginButton(loginText, setLoginText)}
        <hr className="mt-4" />
        <div className="text-center">
          <a className={"forgotPassword"} href={Routes.forgot_password_path()}>
            {I18n.t("forgot_password_link")}
          </a>
        </div>
        <hr />
        <div className="text-center">
          <small className={"newHere"}>
            {I18n.t("login_no_account_question")}
          </small>
          <a className={"doSignUp"} href={Routes.signup_path()}>
            {" "}
            {I18n.t("sign_up")}
          </a>
        </div>
      </OriolaThemeProvider>
    </Form>
  );
}

export default UserLogin;
